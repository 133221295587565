export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const CREATELAB_FAIL = "CREATELAB_FAIL";
export const CREATELAB_SUCCESS = "CREATELAB_SUCCESS";
export const CREATECHALLENGE_FAIL = "CREATECHALLENGE_FAIL";
export const CREATECHALLENGE_SUCCESS = "CREATECHALLENGE_SUCCESS";
export const BOOKCHALLENGE_FAIL = "BOOKCHALLENGE_FAIL";
export const BOOKCHALLENGE_SUCCESS = "BOOKCHALLENGE_SUCCESS";
export const GET_OVPN_SUCCESS = "GET_OVPN_SUCCESS";
export const GET_OVPN_FAIL = "GET_OVPN_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";


import UserService from "../services/user.service";
import { createBooking } from "../actions/labs";
import EventBus from "../common/EventBus";
import { connect } from "react-redux";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vDate = (value) => {
  if (value< 1 || value > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        The date is invalid.
      </div>
    );
  }
};

const vTime = (value) => {
  if (value.startsWith("/")) {
    return (
      <div className="alert alert-danger" role="alert">
        The Time is invalid.
      </div>
    );
  }
};

class bookChallenge extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeDate= this.onChangeDate.bind(this);
    this.onChangeTime= this.onChangeTime.bind(this);
    
    const todayDate = new Date();
    const futureDate = new Date(todayDate.getTime()+1000*60*60*24*10);

    console.log(todayDate);
    console.log(futureDate);
    if(this.props.location.state)
      this.state = {
        display: false,
        content: "",
        labId: this.props.location.state.labId,
        challengeId: this.props.location.state.challengeId,
        challengeName: this.props.location.state.challengeName,
        description: "",
        date: 0,
        todayDate: todayDate,
        futureDate: futureDate,
        minDate: todayDate.getDate()+"/"+(todayDate.getMonth()+1)+"/"+todayDate.getFullYear(),
        maxDate: futureDate.getDate()+"/"+(futureDate.getMonth()+1)+"/"+futureDate.getFullYear(),
        time: 0,
        labName: this.props.location.state.labName,
        successful: false,
      };
    else 
      this.state = {
        redirect: true
      };
  }

  componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data,
          redirect: false,
          display:true
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          redirect: true
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }

        return <Redirect to={{ pathname: "/not-authorized"}} />;

      }
    );
  //this.fetchLabs();
  }

  // async fetchLabs() {
  //     // Fetch data
  //     const { data } = await labsService.getMyLabs();
  //     const results = [];
  //     // Store results in the results array
  //     data.forEach((value) => {
  //       results.push({
  //         key: value.id,
  //         value: value.name,
  //       });
  //     });
  //     // Update the options state
  //     this.setState({
  //       labs:[...this.state.labs, ...results]
  //       }
  //     )
  //   }

  onChangeDate(e) {
    this.setState({
      date: e.target.value
    });
  }

  onChangeTime(e) {
    this.setState({
      time: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          createBooking(this.state.labId, this.state.challengeId, this.state.date,
                          this.state.time)
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  render() {
    const { message } = this.props;

    if (this.state.redirect){
        return <Redirect
            to="/not-authorized"
            />;
    }

    return (
      <div>
        <div className="container">
          <header className="jumbotron">
            <h3 className="text-center">Book Challenge</h3>
          </header>
        </div>

        <div className="card card2-container">
          <div className="container">
            {this.state.display &&
              <Form
                onSubmit={this.handleRegister}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="justify-content-center">

                      <div className="form-group col-md-6">
                        <label htmlFor="laboratory">Laboratory</label>
                        <Input
                          type="text"
                          className="form-control"
                          value={this.state.labName}
                          name="laboratory"
                          disabled
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="challengeName">Challenge Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="challengeName"
                          value={this.state.challengeName}
                          disabled
                        />
                      </div>
                      
                      <div className="form-group  col-md-12">
                        <label htmlFor="regAdminCert">Date</label>
                        <Input
                          type="date"
                          className="form-control"
                          name="date"
                          value={this.state.date}
                          min={this.state.todayDate.toISOString().split("T")[0]}
                          max={this.state.futureDate.toISOString().split("T")[0]}
                          onChange={this.onChangeDate}
                          validations={[required,vDate]}
                        />
                      </div>

                      <div className="form-group col-md-8">
                        <label htmlFor="maxUsers">Time</label>
                        <Input
                          type="time"
                          className="form-control"
                          name="time"
                          steps="1800"
                          pattern="[0-9]{2}:(00|30))"
                          value={this.state.time}
                          onChange={this.onChangeTime}
                          validations={[required, vTime]}
                        />
                      </div>


                    </div>

                    <div className="d-flex flex-row  justify-content-center">
                      <div className="form-group">
                        <button className="btn btn-primary btn-block">Book Challenge</button>
                      </div>
                    </div>
                  </div>
                  
                )}

                {message && (
                  <div className="form-group">
                    <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(bookChallenge);
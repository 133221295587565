import React, { Component} from "react";

import { Link, Redirect   } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import labsService from "../services/labs.service";


export default class FindLabs extends Component {
  constructor(props) {
    super(props);
    this.bookChallenge=this.bookChallenge.bind(this);

    this.state = {
      display: false,
      toBook: false,
      labId: 2,
      labName: "b",
      challengeName: "a",
      challengeId: 3,
      labs: []
    };
  }

  bookChallenge = () =>{ 
    this.setState({
      toBook: true
    });
  }
  

  componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data,
          redirect: false,
          display: true
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          redirect: true
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    this.fetchAllLabs();
  }

  async fetchAllLabs() {
      // Fetch data
      const { data } = await labsService.getAllLabs();
      // Store results in the results array
      // Update the options state
      this.setState({
        labs: data
        }
      )
    }
  render() {
    if (this.state.redirect){
        return <Redirect
            to="/not-authorized"
            />;
    }

    return (
      <div className="container">
        <header className="jumbotron">
          <h3 className="text-center">Find Labs</h3>
        </header>

        {this.state.display && 
          <div className="container">
            {this.state.labs.map(lab => (
                <Card style={{ width: '80%' }} key={lab.id}>
                      <Card.Img variant="top" src="" />
                        <Card.Title>{lab.name}</Card.Title>
                      <Card.Body>
                        <Card.Link href={lab.ctfdDns+":"+lab.ctfdPort}>CTFD portal</Card.Link>

                          <div className="row">
                          {
                            lab.challenges.map( challenge => (
                                <Card style={{ width: '33%' }} key={challenge.name+lab.id}>
                                    <Card.Title>{challenge.name}</Card.Title>
                                  <Card.Body>
                                    <Card.Text>{challenge.description}</Card.Text>
                                    <Card.Link href={lab.ctfdDns+":"+lab.ctfdPort+"/"+challenge.path}>CTFD challenge page</Card.Link>
                                  </Card.Body>
                                  <Card.Footer className="card-footer text-center">
                                    <Link to={{pathname: '/bookChallenge',
                                               state: {labId: lab.id, 
                                                       labName: lab.name,
                                                       challengeId: challenge.id, 
                                                       challengeName: challenge.name
                                                      }
                                              }}>
                                      <Button className="btn btn-warning btn-sm" variant="primary">Book Challenge</Button>
                                    </Link>
                                  </Card.Footer>
                                </Card>
                              )
                            )
                          }
                          </div>

                      </Card.Body>
                    </Card>
                )
            )
            }
          </div>
          }
        </div>
      );
  }
}

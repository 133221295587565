import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";

import { connect } from "react-redux";
import EventBus from "../common/EventBus";
import { isURL } from "validator";

import { createLab } from "../actions/labs";
import UserService from "../services/user.service";
import DataService from "../services/data.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vDnsName = (value) => {
  if (!isURL(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Should be a valid DNS name.
      </div>
    );
  }
};

const vport = (value) => {
  if (value.length < 4 || value.length > 5) {
    return (
      <div className="alert alert-danger" role="alert">
        The port must be between 1000 and 9999.
      </div>
    );
  }
};

const vCert = (value) => {
  const starts=value.startsWith("-----BEGIN CERTIFICATE-----\n");
  const ends=value.endsWith("-----END CERTIFICATE-----\n")
           || value.endsWith("-----END CERTIFICATE-----");
  
  if (!starts || !ends) {
    return (
      <div className="alert alert-danger" role="alert">
        Invalid certificate format.
      </div>
    );
  }
};

const vKey = (value) => {
  const starts=value.startsWith("-----BEGIN RSA PRIVATE KEY-----\n");
  const ends=value.endsWith("-----END RSA PRIVATE KEY-----\n")
           || value.endsWith("-----END RSA PRIVATE KEY-----");
  
  if (!starts || !ends) {
    return (
      <div className="alert alert-danger" role="alert">
        Invalid key format.
      </div>
    );
  }
};

const vcountry = (value) => {
  if (value==="" || value==="Select a country") {
    return (
      <div className="alert alert-danger" role="alert">
        Please select a country.
      </div>
    );
  }
};

class AddLab extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeName= this.onChangeName.bind(this);
    this.onChangeCountry= this.onChangeCountry.bind(this);
    this.onChangeCtfdDns= this.onChangeCtfdDns.bind(this);
    this.onChangeCtfdPort= this.onChangeCtfdPort.bind(this);
    this.onChangeRegistrarDns= this.onChangeRegistrarDns.bind(this);
    this.onChangeRegistrarPort= this.onChangeRegistrarPort.bind(this);
    this.onChangeCaCert= this.onChangeCaCert.bind(this);
    this.onChangeAdminCert= this.onChangeAdminCert.bind(this);
    this.onChangeAdminKey= this.onChangeAdminKey.bind(this);
    
    this.state = {
      display: false,
      content: "",
      name: "",
      countryId: 0,
      countryName: "Select a country",
      countries:[{key: 0, value: 'Select a country'}],
      ctfdDns: "",
      ctfdPort: 0,
      registrarDns: "",
      registrarPort: 0,
      caCert: "",
      adminCert: "",
      adminKey: "",
      successful: false,
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
          this.setState({
            content: response.data,
            redirect: false,
            display: true
          });
      },
      error => {
        console.log({content: 
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()});
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          redirect: true
        });
        if (error.response && error.response.status === 401) {
          console.log("  Dispatch logout");
          EventBus.dispatch("logout");
        }
        return <Redirect to={{ pathname: "/not-authorized"}} />;
      }
    );
    this.fetchCountries();
  }

  async fetchCountries() {
      // Fetch data
      const { data } = await DataService.getCountries();
      const results = [];
      // Store results in the results array
      data.forEach((value) => {
        results.push({
          key: value.id,
          value: value.name,
        });
      });
      // Update the options state
      this.setState({
        countries:[...this.state.countries, ...results]
        }
      )
    }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeCountry(e) {
    this.setState({
      countryId: e.target.selectedIndex,
      countryName: e.target.value
    });
  }

  onChangeCtfdDns(e) {
    this.setState({
      ctfdDns: e.target.value,
    });
  }

  onChangeCtfdPort(e) {
    this.setState({
      ctfdPort: e.target.value,
    });
  }

  onChangeRegistrarDns(e) {
    this.setState({
      registrarDns: e.target.value,
    });
  }

  onChangeRegistrarPort(e) {
    this.setState({
      registrarPort: e.target.value,
    });
  }  

  onChangeCaCert(e) {
    this.setState({
      caCert: e.target.value,
    });
  }

  onChangeAdminCert(e) {
    this.setState({
      adminCert: e.target.value,
    });
  }

  onChangeAdminKey(e) {
    this.setState({
      adminKey: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          createLab(this.state.name, this.state.countryId, this.state.ctfdDns, 
                    this.state.ctfdPort, this.state.registrarDns, this.state.registrarPort, 
                    this.state.caCert, this.state.adminCert, this.state.adminKey)
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  render() {
    const { message } = this.props;
    if (this.state.redirect){
        return <Redirect
            to="/not-authorized"
            />;
    }

    return (
      <div>
        <div className="container">
          <header className="jumbotron">
            <h3 className="text-center">Add New Lab</h3>
          </header>
        </div>

        <div className="card card2-container">
          {this.state.display &&
            <div className="container">
              <Form
                onSubmit={this.handleRegister}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="d-flex flex-row  justify-content-center">
                      <div className="form-group col-md-6">
                        <label htmlFor="labName">Laboratory Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="labName"
                          value={this.state.name}
                          onChange={this.onChangeName}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="password">Country</label>
                        <Select
                          className="form-control"
                          onChange={this.onChangeCountry}
                          value={this.state.countryName}
                          name="country"
                          validations={[vcountry, required]}
                        >
                          {this.state.countries.map(country => (
                              <option key={country.key} value={country.value} disabled={(country.key===0)?true:false}>
                                {country.value}
                              </option>
                              )
                            )
                          }
                        </Select>
                      </div>
                    </div>

                    <div className="d-flex flex-row  justify-content-center">
                      <div className="form-group col-md-8">
                        <label htmlFor="ctfdDnsName">CTFD DNS name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="ctfdDnsName"
                          value={this.state.ctfdDns}
                          onChange={this.onChangeCtfdDns}
                          validations={[required, vDnsName]}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="ctfdPort">CTFD Port</label>
                        <Input
                          type="number"
                          className="form-control"
                          name="ctfdPort"
                          value={this.state.ctfdPort}
                          onChange={this.onChangeCtfdPort}
                          validations={[required, vport]}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row  justify-content-center">
                      <div className="form-group  col-md-8">
                        <label htmlFor="regDnsName">Registrar DNS name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="regDnsName"
                          value={this.state.registrarDns}
                          onChange={this.onChangeRegistrarDns}
                          validations={[required, vDnsName]}
                        />
                      </div>

                      <div className="form-group  col-md-4">
                        <label htmlFor="regPort">Registrar Port</label>
                        <Input
                          type="number"
                          className="form-control"
                          name="regPort"
                          value={this.state.registrarPort}
                          onChange={this.onChangeRegistrarPort}
                          validations={[required, vport]}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column  justify-content-center">
                    <div className="form-group col-md-12">
                        <label htmlFor="regCaCert">Registrar CA certificate</label>
                        <Textarea
                          type="text"
                          className="form-control"
                          name="regCaCert"
                          value={this.state.caCert}
                          onChange={this.onChangeCaCert}
                          validations={[required, vCert]}
                        />
                      </div>

                      <div className="form-group  col-md-12">
                        <label htmlFor="regAdminCert">Registrar admin certificate</label>
                        <Textarea
                          type="text"
                          className="form-control"
                          name="regAdminCert"
                          value={this.state.adminCert}
                          onChange={this.onChangeAdminCert}
                          validations={[required, vCert]}
                        />
                      </div>

                      <div className="form-group col-md-12">
                        <label htmlFor="regAdminKey">Registrar admin key</label>
                        <Textarea
                          type="text"
                          className="form-control"
                          name="regAdminKey"
                          value={this.state.adminKey}
                          onChange={this.onChangeAdminKey}
                          validations={[required, vKey]}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row  justify-content-center">
                      <div className="form-group">
                        <button className="btn btn-primary btn-block">Add Laboratory</button>
                      </div>
                    </div>
                  </div>
                )}

                {message && (
                  <div className="form-group">
                    <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
              </div>
              }
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(AddLab);

import {
  CREATELAB_SUCCESS,
  CREATELAB_FAIL,
  CREATECHALLENGE_SUCCESS,
  CREATECHALLENGE_FAIL,
  BOOKCHALLENGE_SUCCESS,
  BOOKCHALLENGE_FAIL,
  GET_OVPN_SUCCESS,
  GET_OVPN_FAIL,

  SET_MESSAGE,
} from "./types";

import LabService from "../services/labs.service";

export const createLab = (name, country, ctfdDns, ctfdPort, registrarDns, registrarPort, 
                          caCert, adminCert, adminKey) => (dispatch) => {
  return LabService.createLab(name, country, ctfdDns, ctfdPort, registrarDns, registrarPort, 
                          caCert, adminCert, adminKey).then(
    (response) => {
      dispatch({
        type: CREATELAB_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATELAB_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const createChallenge = (labId, name, description, maxUsers, path) => (dispatch) => {
  return LabService.createChallenge(labId, name, description, maxUsers, path).then(
    (response) => {
      dispatch({
        type: CREATECHALLENGE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATECHALLENGE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};



export const createBooking = (labId, challengeId, date, time) => (dispatch) => {
  return LabService.createBooking(labId, challengeId, date, time).then(
    (response) => {
      dispatch({
        type: BOOKCHALLENGE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: BOOKCHALLENGE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getLabBookingOVPN = (bookingId) => (dispatch) => {
  return LabService.getLabBookingOVPN(bookingId).then(
    (response) => {
      dispatch({
        type: GET_OVPN_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_OVPN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

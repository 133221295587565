import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Link  } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import myBookings from "./components/myBookings.component";
import FindLabs from "./components/findLabs.component";
import AddLab from "./components/addLab.component";
import AddChallenge from "./components/addChallenge.component";
import BookChallenge from "./components/bookChallenge.component";


import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from './helpers/history';

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import labsService from "./services/labs.service";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      adminUser: false,
      normalUser: undefined,
      hasLabs: false
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  async componentDidMount() {
    const user = this.props.user;

    if (user) {
      let hasLabs=false;
      try {
        const labs= await labsService.getMyLabs();
        hasLabs=(labs.data.length!==0);
      }catch{
        this.logOut();
        return;
      }
      this.setState({
        normalUser: user,
        showLabManagerBoard: user.roles.includes("ROLE_LAB_MANAGER"),
        adminUser: user.roles.includes("ROLE_ADMIN"),
        hasLabs: hasLabs
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      // showModeratorBoard: false,
      adminUser: false,
      showLabManagerBoard: false,
      hasLabs: false,
      normalUser: undefined,
    });
  }

  render() {
    const { adminUser, normalUser, hasLabs } = this.state;

    return (
      <Router history={history}>
        <div>
          <nav className="navbar navbar-expand-sm navbar-dark myNavBar">
            <Link to={"/"} className="navbar-brand">
              <img src="/logo_name.png" alt="..." height="50"/>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" 
                    data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" 
                    aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav -auto">
                <li className="nav-item" >
                  <Link to={"/home"} className="nav-link mx-2">
                    Home
                  </Link>
                </li>
                {adminUser && (
                  <li className="nav-item" >
                    <Link to={"/addLab"} className="nav-link mx-2">
                      Add Lab
                    </Link>
                  </li>
                )}
                
                {(adminUser && hasLabs) && (
                  <li className="nav-item" >
                    <Link to={"/addChallenge"} className="nav-link mx-2">
                      Add Challenge
                    </Link>
                  </li>
                )}

                {normalUser && (
                  <li className="nav-item" >
                    <Link to={"/findLabs"} className="nav-link mx-2">
                      Find Labs
                    </Link>
                  </li>
                )}

                {normalUser && (
                  <li className="nav-item" >
                    <Link to={"/myBookings"} className="nav-link mx-2">
                      My Bookings
                    </Link>
                  </li>
                )}
              </ul>

            <div className="navbar-item-content-end">
              {normalUser ? (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item" >
                    <Link to={"/profile"} className="nav-link mx-2">
                      {normalUser.username}
                    </Link>
                  </li>
                  <li className="nav-item" >
                    <a href="/login" className="nav-link mx-2" onClick={this.logOut}>
                      LogOut
                    </a>
                  </li>
                </div>
              ) : (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item" >
                    <Link to={"/login"} className="nav-link mx-2">
                      Login
                    </Link>
                  </li>

                  <li className="nav-item" >
                    <Link to={"/register"} className="nav-link mx-2">
                      Sign Up
                    </Link>
                  </li>
                </div>
              )}
              </div>
            </div>
          </nav>

          <div className="container mt-3">
            <Switch>
              <Route exact path={["/", "/home"]} component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/addLab" component={AddLab} />
              <Route path="/addChallenge" component={AddChallenge} />
              <Route path="/bookChallenge" component={BookChallenge} />
              <Route path="/findLabs" component={FindLabs} />
              <Route path="/myBookings" component={myBookings} />
              <Route path="/not-authorized" component={() => <h1>Not Authorized</h1>} />

            </Switch>
          </div>

          {/* <AuthVerify logOut={this.logOut}/> */}
          <footer>
            <div className="row justify-content-md-center"> 
              <div className="col" style={{maxWidth: "160px"}}>
                <a href="http://dlearn.eu/" target="_blank" rel="noopener noreferrer">
                  <img style={{maxWidth: "150px", padding: "20px", paddingBottom: "0vh", align: "center", marginLeft: "0px"}} alt="DLearn logo" src="/images/DLearn.png"/>
                </a>
              </div>
              <div className="col" style={{maxWidth: "160px"}}>
                <a href="https://hmu.gr/" target="_blank" rel="noopener noreferrer"><img style={{maxWidth: "150px", padding: "20px", paddingBottom: "0vh", align: "center", marginLeft: "0px"}} alt="HMU logo" src="/images/hmu-logo.png"/></a>
              </div>
              <div className="col" style={{maxWidth: "160px"}}>
                <a href="https://trainingcentre.gr/" target="_blank" rel="noopener noreferrer">
                  <img style={{maxWidth: "150px", padding: "20px", paddingBottom: "0vh", align: "center", marginLeft: "0px"}} alt="IDEC logo" src="/images/IDEC.png"/>
                </a>
              </div>
              <div className="col" style={{maxWidth: "160px"}}>
                  <a href="https://www.isep.ipp.pt/" target="_blank" rel="noopener noreferrer"><img style={{maxWidth: "150px", padding: "20px", paddingBottom: "0vh", align: "center", marginLeft: "0px"}} alt="ISEP logo" src="/images/ISEP_marca_v2_cor_grande.png"/></a><br/>
                  <a href="https://www.ipp.pt/" target="_blank" rel="noopener noreferrer"><img style={{maxWidth: "150px", padding: "20px", paddingBottom: "0vh", align: "center", marginLeft: "0px"}} alt="ISEP logo" src="/images/PPorto-cor-vazado-01.png"/></a>
              </div>
              <div className="col" style={{maxWidth: "160px"}}>
                <a href="https://politeknikatxorierri.eus/" target="_blank" rel="noopener noreferrer"><img style={{maxWidth: "150px", padding: "20px", paddingBottom: "0vh", align: "center", marginLeft: "0px"}} alt="Txorierri logo" src="/images/txorierri.png"/></a>
              </div>
            </div>
            <div className="row justify-content-md-center ">
              <img className="col align-self-center"  style={{maxWidth: "275px", padding: "20px", paddingBottom: "0vh", float: "right", marginLeft: "0px"}} alt="EU-Erasmus+ logo" src="/images/EU_logo.png"/>
              <div className="col-sm"
                    style={{verticalAlign: "middle", maxWidth: "1000px", float: "left", padding: "20px"}}>
                This project has been funded with support from the European Commission. This publication reflects the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.
              </div>
            </div>
          </footer>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);

import React, { Component } from "react";
import { Redirect  } from 'react-router-dom';

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import labsService from "../services/labs.service";

import { getLabBookingOVPN } from "../actions/labs";
import { connect } from "react-redux";
import fileDownload from 'js-file-download'


class myBookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      content: "",
      bookings: []
    };
  }

  handleDownload = (bookingId, labName, challengeName) => {
    this.props
        .dispatch(
          getLabBookingOVPN(bookingId)
        )
        .then(() => {
          this.setState({
            successful: true,
          });

          fileDownload(this.props.message, labName+'('+challengeName+').ovpn');
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
  }

  componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data,
          redirect: false,
          display: true
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          redirect: true
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        return <Redirect to={{ pathname: "/not-authorized"}} />;
      }
    );
    this.fetchMyBookings();
  }

  async fetchMyBookings() {
      // Fetch data
      const { data } = await labsService.getMyBookings();
      // Store results in the results array
      // Update the options state
      this.setState({
        bookings: data
        }
      )
    }

  render() {
    if (this.state.redirect){
        return <Redirect
            to="/not-authorized"
            />;
    }

    return (
      <div>
        <div className="container">
          <header className="jumbotron">
            <h3 className="text-center">My Scheduled Challenges</h3>
          </header>
        </div>
        <div>
          {this.state.display &&
            this.state.bookings.map(booking => (
                <Card style={{ width: '80%' }} key={booking.id}>
                      <Card.Img variant="top" src="" />
                        <Card.Title>{booking.lab.name}</Card.Title>
                      <Card.Body>
                        <Card.Link href={booking.lab.ctfdDns+":"+booking.lab.ctfdPort+"/"+booking.challenge.path}>CTFD challenge page</Card.Link>
                          
                        <Card.Footer className="card-footer text-center">
                          <b>Challenge:</b> {booking.challenge.name}<br/><br/>
                          <Button className="btn btn-warning btn-sm" variant="primary"
                                  onClick={()=>{this.handleDownload(booking.id, booking.lab.name, booking.challenge.name)}}
                          >Download</Button>
                        </Card.Footer>

                      </Card.Body>
                    </Card>
                )
            )
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(myBookings);
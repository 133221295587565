import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_HOST + "/api/lab/";

class LabsService {
  createLab(name, country, ctfdDns, ctfdPort, registrarDns, registrarPort, 
                          caCert, adminCert, adminKey) {
    return axios.post(API_URL + "createLab",{
      name,
      country,
      ctfdDns,
      ctfdPort,
      registrarDns,
      registrarPort, 
      caCert,
      adminCert,
      adminKey
    }, { headers: authHeader() });
  }

  getLabBookingOVPN(bookingId) {
    return axios.post(API_URL + "getBookingOVPN",{
      bookingId
    }, { headers: authHeader() });
  }

  createChallenge(labId, name, description, maxUsers, path) {
    return axios.post(API_URL + "createChallenge",{
      labId, 
      name, 
      description, 
      maxUsers,
      path
    }, { headers: authHeader() });
  }

    createBooking(labId, challengeId, date, time) {
    return axios.post(API_URL + "createBooking",{
      labId, 
      challengeId, 
      date, 
      time
    }, { headers: authHeader() });
  }

  getMyLabs(){
      return axios.get(API_URL + "getMyLabs", { headers: authHeader() });
  }

  getMyBookings(){
      return axios.get(API_URL + "getMyBookings", { headers: authHeader() });
  }

  getAllLabs(){
      return axios.get(API_URL + "getAllLabs", { headers: authHeader() });
  }


}

export default new LabsService();


import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_HOST + "/api/data/";

class DataService {    
  getCountries() {
      return axios.get(API_URL + "countries");
  }
}

export default new DataService();

import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_HOST + "/api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, country, schoolRole, school) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      country,
      schoolRole,
      school
    });
  }



}

export default new AuthService();

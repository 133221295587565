import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input  from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { register } from "../actions/auth";
import DataService from "../services/data.service";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const vcountry = (value) => {
  if (value==="" || value==="Select a country") {
    return (
      <div className="alert alert-danger" role="alert">
        Please select a country.
      </div>
    );
  }
};

const vschoolrole = (value, props, components) => {
  if (!components.schoolRole[0].checked 
      && !components.schoolRole[1].checked 
      && !components.schoolRole[2].checked 
     ) {
      return (
      <div className="alert alert-danger" role="alert">
        Please select School Role.
      </div>
    );
  }
};

const vschool = (value, props, components) => {
  if ((components.schoolRole[0].checked 
      || components.schoolRole[1].checked 
     ) && value==="") {
      return (
      <div className="alert alert-danger" role="alert">
        Please select School (only for teacher or student).
      </div>
    );
  }
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeCountry= this.onChangeCountry.bind(this);
    this.onChangeSchool= this.onChangeSchool.bind(this);
    this.onChangeSchoolRole= this.onChangeSchoolRole.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      countryId: 0,
      countryName: "Select a country",
      countries:[{key: 0, value: 'Select a country'}],
      schoolRole: -1,
      school: "",
      successful: false,
    };
  }

  componentDidMount() {
    // Trigger the fetch
    this.fetchCountries();
  }

  async fetchCountries() {
      // Fetch data
      const { data } = await DataService.getCountries();
      const results = [];
      // Store results in the results array
      data.forEach((value) => {
        results.push({
          key: value.id,
          value: value.name,
        });
      });
      // Update the options state
      this.setState({
        countries:[...this.state.countries, ...results]
        }
      )
    }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeCountry(e) {
    this.setState({
      countryId: e.target.selectedIndex,
      countryName: e.target.value
    });
  }

  onChangeSchoolRole(e) {
    this.setState({
      schoolRole: e.target.value
    });
  }

  onChangeSchool(e) {
    this.setState({
      school: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          register(this.state.username, this.state.email, this.state.password, this.state.countryId, this.state.schoolRole, this.state.school)
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">
        <div className="card card2-container">
          <img className="align-items-center justify-content-center"
            src="/logo_name.png"
            alt="profile-img"
            width="300"
          />

          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Country</label>
                  <Select
                    className="form-control"
                    onChange={this.onChangeCountry}
                    value={this.state.countryName}
                    name="country"
                    validations={[vcountry, required]}
                  >
                    {this.state.countries.map(country => (
                        <option key={country.key} value={country.value} disabled={(country.key===0)?true:false}>
                          {country.value}
                        </option>
                        )
                      )
                    }
                  </Select>


                </div>
                  
                <div className="form-group">
                  <label htmlFor="email">School role</label>
                  <div className="form-check form-check-inline"
                        onChange={this.onChangeSchoolRole}
                  >
                      <Input
                          className="form-check-input radio-class"
                          name="schoolRole"
                          type="radio"
                          value="0"
                          style={{marginLeft: "10px"}}
                         
                        />
                      <label className="form-check-label">Teacher</label>
                      <Input
                        className="form-check-input radio-class"
                        type="radio"
                        name="schoolRole"
                        value="1"
                        style={{marginLeft: "10px"}}
                      />
                      <label className="form-check-label">Student</label>                      
                        <Input
                          className="form-check-input radio-class"
                          type="radio"
                          name="schoolRole"
                          value="2"
                          style={{marginLeft: "10px"}}
                        />
                      <label className="form-check-label">Other</label>                      
                  </div>
                  <Input
                          className="form-check-input"
                          type="hidden"
                          name="schoolRole"
                          value=""
                           ref={c => { this.schoolRole = c }}
                          validations={[vschoolrole]}
                        />
                </div>

                <div className="form-group">
                  <label htmlFor="email">School</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.school}
                    onChange={this.onChangeSchool}
                    validations={[vschool]}
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-primary btn-block">Sign Up</button>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);

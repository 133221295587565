import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";


import UserService from "../services/user.service";
import { createChallenge } from "../actions/labs";
import EventBus from "../common/EventBus";
import { connect } from "react-redux";
import labsService from "../services/labs.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vMaxUsers = (value) => {
  if (value< 1 || value > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        The Maximum users must be between 1 and 30.
      </div>
    );
  }
};

const vPath = (value) => {
  if (value.startsWith("/")) {
    return (
      <div className="alert alert-danger" role="alert">
        The path must be relative (without starting with /).
      </div>
    );
  }
};

const vLab = (value) => {
  if (value==="" || value==="Select a laboratory") {
    return (
      <div className="alert alert-danger" role="alert">
        Please select a laboratory.
      </div>
    );
  }
};

class AddChallenge extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeName= this.onChangeName.bind(this);
    this.onChangeMaxUsers= this.onChangeMaxUsers.bind(this);
    this.onChangeLab= this.onChangeLab.bind(this);
    this.onChangePath= this.onChangePath.bind(this);
    this.onChangeDescription= this.onChangeDescription.bind(this);
    
    this.state = {
      display: false,
      content: "",
      name: "",
      description: "",
      maxUsers: 10,
      path: "",
      labId: 0,
      labName: "Select a laboratory",
      labs: [{key: 0, value: 'Select a laboratory'}],
      successful: false,
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
          this.setState({
            content: response.data,
            redirect: false,
            display: true
          });
          this.fetchLabs();
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          redirect: true
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        return <Redirect to={{ pathname: "/not-authorized"}} />;
      }
    );

  }

  async fetchLabs() {
      // Fetch data
      const { data } = await labsService.getMyLabs();
      const results = [];
      
      // Store results in the results array
      data.forEach((value) => {
        results.push({
          key: value.id,
          value: value.name,
        });
      });
      // Update the options state
      this.setState({
        labs:[...this.state.labs, ...results]
        }
      )
    }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeLab(e) {
    this.setState({
      labId: e.target.selectedIndex,
      labName: e.target.value    
    });
  }

  onChangeMaxUsers(e) {
    this.setState({
      maxUsers: e.target.value,
    });
  }

  onChangePath(e) {
    this.setState({
      path: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          createChallenge(this.state.labId, this.state.name, this.state.description,
                          this.state.maxUsers, this.path)
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  render() {
    const { message } = this.props;
    if (this.state.redirect){
        return <Redirect
            to="/not-authorized"
            />;
    }

    return (
      <div>
        <div className="container">
          <header className="jumbotron">
            <h3 className="text-center">Add Challenge</h3>
          </header>
        </div>

        <div className="card card2-container">
          <div className="container">
            {this.state.display &&
              <Form
                onSubmit={this.handleRegister}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="justify-content-center">

                      <div className="form-group col-md-6">
                        <label htmlFor="laboratory">Laboratory</label>
                        <Select
                          className="form-control"
                          onChange={this.onChangeLab}
                          value={this.state.labName}
                          name="laboratory"
                          validations={[required,vLab]}
                        >
                          {this.state.labs.map(lab => (
                              <option key={lab.key} value={lab.value} disabled={(lab.key===0)?true:false}>
                                {lab.value}
                              </option>
                              )
                            )
                          }
                        </Select>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="challengeName">Challenge Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="challengeName"
                          value={this.state.name}
                          onChange={this.onChangeName}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group  col-md-12">
                        <label htmlFor="regAdminCert">Description</label>
                        <Textarea
                          type="text"
                          className="form-control"
                          name="description"
                          value={this.state.description}
                          onChange={this.onChangeDescription}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group col-md-8">
                        <label htmlFor="maxUsers">Maximum Users</label>
                        <Input
                          type="number"
                          className="form-control"
                          name="maxUsers"
                          value={this.state.maxUsers}
                          onChange={this.onChangeMaxUsers}
                          validations={[required, vMaxUsers]}
                        />
                      </div>

                      <div className="form-group col-md-8">
                        <label htmlFor="path">CTFD Challenge page</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="path"
                          value={this.state.path}
                          onChange={this.onChangePath}
                          validations={[required, vPath]}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row  justify-content-center">
                      <div className="form-group">
                        <button className="btn btn-primary btn-block">Add Challenge</button>
                      </div>
                    </div>
                  </div>
                )}

                {message && (
                  <div className="form-group">
                    <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
             }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(AddChallenge);
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_HOST + "/api/test/";
class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
          // .catch((error)=>{
          //     if (error.response) {
          //       // The request was made and the server responded with a status code
          //       // that falls out of the range of 2xx
          //       console.log(error.response.data);
          //       console.log(error.response.status);
          //       console.log(error.response.headers);
          //     } else if (error.request) {
          //       // The request was made but no response was received
          //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          //       // http.ClientRequest in node.js
          //       console.log(error.request);
          //     } else {
          //       // Something happened in setting up the request that triggered an Error
          //       console.log('Error', error.message);
          //     }
          //     console.log(error.config);
          // });
  }

  getAdminBoard() {
    return axios
          .get(API_URL + "admin", { headers: authHeader() });
          // .catch((error)=>{
          //     if (error.response) {
          //       // The request was made and the server responded with a status code
          //       // that falls out of the range of 2xx
          //       console.log(error.response.data);
          //       console.log(error.response.status);
          //       console.log(error.response.headers);
          //     } else if (error.request) {
          //       // The request was made but no response was received
          //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          //       // http.ClientRequest in node.js
          //       console.log(error.request);
          //     } else {
          //       // Something happened in setting up the request that triggered an Error
          //       console.log('Error', error.message);
          //     }
          //     console.log(error.config);
          // });
  }
}

export default new UserService();
